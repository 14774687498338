.Car-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	flex: 1;
	//align-items: flex-start;
	width: 100%;
	max-width: 1000px;
	margin:auto;
}
  
.car-card {
	display: block;
	flex: 100%;
	//min-width:18rem;
	//max-width:22rem;
	//height: 150px;
	//position: relative;
	//box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
	//background-size: cover;
	//overflow-x: hidden;
	//overflow-y: hidden;
	margin: 10px;
}
  
.car-media {
	display: block;
	//flex: 25%;
	width: 100%;
	//min-width:250px;
	height: 190px;
	position: relative;
	//border-radius: 5px;
	//box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
	//background-size: cover;
	overflow-x: hidden;
	overflow-y: hidden;
	//margin: 20px;
}
  
.add-button {
	color: default;
	width: 130px;
}

.card-content {
	padding: 2px !important;
}

.Car-name {
	color: rgba(0,0,0,.87);
	font-weight: 400 !important;
	font-size: 20px !important;
	padding: 3px;
}

.Car-cardOverlay {
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	background: -webkit-linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 50%); /* safari5.1+,chrome10+ */
	top: 0px;
	left: 0px;
}
  
.metric-container {
	display: flex;
	justify-content: center;
	padding: 5px;
	font-weight: 400;
	color: rgba(0,0,0,.6);
	font-size: 14px !important;
}

.car-metric {
	width: 50%;
}

.car-metric-group {
	padding-top: 0px;
}

@media only screen and (max-width: 600px) {

	.car-card {
		margin: 7px;
	}
}

@media only screen and (min-width: 1300px) {
	.Car-list {
		width: 1300px;
	}
}

@media only screen and (min-width: 600px) {

	.card-card {
		max-width: 800px;
	}
	.car-action-area {
		display: flex !important;
		flex: 1;
	}

	.card-top {
		display: flex;
		flex-direction: row;
	}

	.Car-name {
		width: 80%;
	}

	.car-media {
		width: 40%;
	}

	.card-content {
		width: 60%;
	}

	.car-metric-group {
		margin-top: 20px;
	}
}