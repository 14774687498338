.App {
	text-align: center;
	background-color: #f9f9f9;
	font-family: 'Roboto', sans-serif;
}

.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 40vmin;
	pointer-events: none;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.cars-to-compare {
	position: fixed;
	bottom: 0px;
	display: flex;
	flex-direction: column;
	padding: 4px;
}

.car-chip {
	margin: 4px;
}

.MuiChip-label {
	width: 100%;
}

@media only screen and (max-width: 600px) {
	.textField {
		width: 150px;
	}

	.textInput {
		width: 150px;
	}
}