.Detail-paper {
    margin: auto;
    overflow:auto;
    margin-top: 20px;
    width: 95%;
}

.Detail-price {
    margin-top: 5px;
}
.Detail-metrics {
    display: table;
    margin: 0 auto;
    text-align: left;
}

.Detail-table {
    width: 300px;
}

.info-box {
    max-width: 600px;
    margin: auto;
}
.flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.row {
    margin: auto;
}
.row-title {
    width: 120px;
    text-align: left;
}

.row-cell {
    float: right;
    width: 120px;
}